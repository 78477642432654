import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { lazy, Suspense } from 'react';
import Loader from './components/Elements/Loader';
import ProjectDetailInternacionalnihBrigada from './components/Pages/ProjectDetailInternacionalnihBrigada';
import FloorIB from './components/Pages/FloorIB';
import ApartmentIB from './components/Pages/ApartmentIB';
import GarageIB from './components/Pages/GarageIB';
// Page Component Imports

const Home = lazy(() => import('./components/Pages/Home'));
const ContactUs = lazy(() => import('./components/Pages/ContactUs'));
const About = lazy(() => import('./components/Pages/About'));
const Error404 = lazy(() => import('./components/Pages/Error404'));
const Projects = lazy(() => import('./components/Pages/Projects'));
const Apartment = lazy(() => import('./components/Pages/Apartment'));
const Floor = lazy(() => import('./components/Pages/Floor'));
const Header = lazy(() => import('./components/Elements/Header'));
const Footer = lazy(() => import('./components/Elements/Footer'));
const ProjectDetail = lazy(() =>
  import('./components/Pages/ProjectDetailDravska')
);
const ProjectDetailMilanaRakica = lazy(() =>
  import('./components/Pages/ProjectDetailsMilanaRakica')
);
const ProjectDetailLaMartinova = lazy(() =>
  import('./components/Pages/ProjectDetailsLaMartinova')
);
const Office = lazy(() => import('./components/Pages/Office'));
const Garage = lazy(() => import('./components/Pages/Garage'));
const ScrollToTop = lazy(() => import('./components/Elements/ScrollToTop'));

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Header />
          {/* <Loader /> */}

          <Route
            render={({ location }) => (
              <>
                <Switch>
                  <Route path="/sprat/:broj/stan/:id">
                    <Apartment />
                  </Route>
                  <Route path="/sprat/:broj/lokal/:id">
                    <Office />
                  </Route>
                  <Route path="/sprat/:broj/garaze/:id">
                    <Garage />
                  </Route>

                  <Route path="/internacionalnih-brigada/sprat/:broj/stan/:id">
                    <ApartmentIB />
                  </Route>
                  <Route path="/internacionalnih-brigada/sprat/:broj">
                    <FloorIB />
                  </Route>
                  <Route path="/sprat/:broj">
                    <Floor />
                  </Route>
                </Switch>
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames="fade"
                  >
                    <>
                      <ScrollToTop />
                      <Switch location={location}>
                        <Route exact path="/">
                          <Home />
                        </Route>
                        <Route exact path="/kontakt">
                          <ContactUs />
                        </Route>
                        <Route exact path="/onama">
                          <About />
                        </Route>
                        <Route exact path="/projekti">
                          <Projects />
                        </Route>
                        <Route path="/internacionalnih-brigada/garaze">
                          <GarageIB />
                        </Route>
                        {/* <Route path="/sprat/:broj/stan/:id">
                        <Apartment />
                      </Route>
                      <Route path="/sprat/:broj/lokal/:id">
                        <Office />
                      </Route> */}
                        {/* <Route path="/sprat/:broj">
                      <Floor />
                    </Route> */}
                        <Route path="/dravska">
                          <ProjectDetail />
                        </Route>
                        <Route path="/milana-rakica">
                          <ProjectDetailMilanaRakica />
                        </Route>
                        <Route path="/lamartinova">
                          <ProjectDetailLaMartinova />
                        </Route>
                        <Route exact path="/internacionalnih-brigada">
                          <ProjectDetailInternacionalnihBrigada />
                        </Route>
                        {!location.pathname.includes('sprat') && (
                          <Route>
                            <Error404 />
                          </Route>
                        )}
                      </Switch>
                    </>
                  </CSSTransition>
                </TransitionGroup>
              </>
            )}
          />
          <Footer />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
