import React from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';

let bgimage = require('./../../images/background/bg-site.png');
let logo = require('./../../images/logo_abm.png');

const Footer = () => {
  return (
    <>
      <footer className="site-footer footer-large  footer-dark	footer-wide">
        <div
          className="container call-to-action-wrap bg-no-repeat bg-center"
          style={{ backgroundImage: 'url(' + bgimage.default + ')' }}
        >
          {/* <div className="p-a30 bg-primary ">
            <div className="row">
              <div className="col-md-8 col-sm-8">
                <div className="call-to-action-left text-black">
                  <h4 className="text-uppercase m-b10 m-t0">
                    Subscribe to our newsletter!
                  </h4>
                  <span>
                    Never Miss Anything From Construx By Signing Up To Our
                    Newsletter.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="call-to-action-right">
                  <div className="widget_newsletter">
                    <div className="newsletter-bx">
                      <form role="search" action="./">
                        <div className="input-group">
                          <input
                            name="news-letter"
                            className="form-control"
                            placeholder="ENTER YOUR EMAIL"
                            type="text"
                          />
                          <span className="input-group-btn">
                            <button type="submit" className="site-button">
                              <i className="fa fa-paper-plane-o" />
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* FOOTER BLOCKES START */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* ABOUT COMPANY */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_about">
                  {/*<h4 class="widget-title">About Company</h4>*/}
                  <div className="logo-footer clearfix p-b15">
                    {/* <NavLink to={"./"}><img src="./../../images/logo-dark.png" alt="" /></NavLink> */}
                    <img
                      src={logo.default}
                      alt="ABM"
                      width="125px"
                      height="100%"
                      style={{ paddingBottom: '10px', objectFit: 'contain' }}
                    />
                  </div>

                  <ul className="social-icons  mt-social-links">
                    <li>
                      <Link
                        to={{
                          pathname:
                            'https://www.instagram.com/abm.art.property/?utm_medium=copy_link',
                        }}
                        target="_blank"
                        className="fa fa-instagram"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/* RESENT POST */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_address_outer">
                  <h4 className="widget-title">Kontakt</h4>
                  <ul className="widget_address">
                    <li>Hadži Melentijeva 28, Vračar, Beograd</li>
                    <li>
                      <a
                        style={{ color: 'gray' }}
                        href="mailto:office@abmartproperty.rs"
                      >
                        office@abmartproperty.rs
                      </a>
                    </li>
                    <li>
                      <a style={{ color: 'gray' }} href="tel:011/242-02-15">
                        011/242-02-15
                      </a>
                    </li>
                    <li>
                      <a style={{ color: 'gray' }} href="tel:065/25-80-280">
                        065/25-80-280
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* USEFUL LINKS */}
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                <div className="widget widget_services inline-links">
                  <h4 className="widget-title">Korisni Linkovi</h4>
                  <ul>
                    <li>
                      <NavLink to={'/onama'}>O Nama</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/projekti'}>Projekti</NavLink>
                    </li>
                    <li>
                      <NavLink to={'/kontakt'}>Kontakt</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* TAGS */}
              {/* NEWSLETTER */}
            </div>
          </div>
        </div>
        {/* FOOTER COPYRIGHT */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              <div className="mt-footer-bot-center">
                <span className="copyrights-text">
                  © 2021 ABM Art Property.
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
