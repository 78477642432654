import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Banner from '../Elements/Banner';
import {
  constants,
  spratovi,
  brojeviSpratovaNeprodati,
} from '../../constants/constants';
let bnrimg = require('./../../images/banner/Dravskanova.jpg');

const Floor = () => {
  const { broj } = useParams();
  const brojeviSpratovaNeprodati = [0, 1, 2, 3, 4, 5, 6, 7];
  const history = useHistory();

  const floorUp = () => {
    let index = brojeviSpratovaNeprodati.indexOf(
      brojeviSpratovaNeprodati.find((sprat) => sprat == broj)
    );
    let sprat = brojeviSpratovaNeprodati[index + 1];

    if (index == brojeviSpratovaNeprodati.length - 1 || index == undefined) {
      history.push('/sprat/' + brojeviSpratovaNeprodati[0]);
      return;
    }
    history.push('/sprat/' + sprat);
  };

  const floorDown = () => {
    let index = brojeviSpratovaNeprodati.indexOf(
      brojeviSpratovaNeprodati.find((sprat) => sprat == broj)
    );
    let sprat = brojeviSpratovaNeprodati[index - 1];

    if (index == undefined || index <= 0) {
      history.push(
        '/sprat/' +
          brojeviSpratovaNeprodati[brojeviSpratovaNeprodati.length - 1]
      );
      return;
    }
    history.push('/sprat/' + sprat);
  };

  return (
    <>
      <Banner
        title=""
        pagename={broj == 0 ? 'Prizemlje' : 'Sprat: ' + broj}
        buildingName="Dravska"
        bgimage={bnrimg.default}
      />
      <div className="mobile-floor-buttons">
        <button
          className="m-b15 site-button  blue text-uppercase m-r15"
          onClick={floorUp}
        >
          <i class="fas fa-arrow-circle-up"></i>{' '}
        </button>
        <button
          className="m-b15 site-button  blue text-uppercase m-r15"
          onClick={floorDown}
        >
          <i class="fas fa-arrow-circle-down"></i>{' '}
        </button>
      </div>
      <div className="blog-post date-style-3 blog-detail text-black">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="floor-buttons"
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            //   marginLeft: '50px',
            //   marginBottom: '30px',
            // }}
          >
            <button
              className="m-b15 site-button  blue text-uppercase m-r15"
              onClick={floorUp}
            >
              <i class="fas fa-arrow-circle-up"></i>{' '}
            </button>
            <button
              className="m-b15 site-button  blue text-uppercase m-r15"
              onClick={floorDown}
            >
              <i class="fas fa-arrow-circle-down"></i>{' '}
            </button>
          </div>
          <div className="mt-post-media clearfix m-b30">
            <ul className="grid-post">
              <li>
                <div className="portfolio-item">
                  {spratovi[broj]?.komponenta}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Floor;
