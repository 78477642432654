import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

const BannerIB = (props) => {
  const routeMatch = useRouteMatch();
  console.log(props.buildingName);
  return (
    <>
      <div
        className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: 'url(' + props.bgimage + ')' }}
      >
        <div className="overlay-main bg-black opacity-07" />
        <div className="container">
          <div className="mt-bnr-inr-entry">
            <div>
              <ul className="mt-breadcrumb breadcrumb-style-2">
                {props.buildingName && (
                  <li>
                    <NavLink
                      to={
                        props?.buildingName != 'Internacionalnih brigada 93'
                          ? '/' + props?.buildingName
                          : '/internacionalnih-brigada'
                      }
                    >
                      <strong id="bannerName">{props?.buildingName}</strong>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerIB;
