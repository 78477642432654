import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from './Navigation';
// import Navigation from '../Common/Navigation';
// import { NavLink } from 'react-router-dom';

let bnr = require('./../../images/background/bg-5.png');
let logo = require('./../../images/logo_abm.png');

const Header = (props) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isQuoteActive, setIsQuoteActive] = useState(false);

  const handleSearchToggle = () => {
    setIsSearchActive((state) => !state);
  };

  const handleQuoteToggle = () => {
    setIsQuoteActive((state) => !state);
  };

  return (
    <>
      <header className="site-header header-style-1">
        <div className="top-bar bg-gray">
          <div className="container">
            <div className="row">
              <div className="mt-topbar-left clearfix">
                <ul className="list-unstyled e-p-bx pull-right">
                  <li>
                    <i className="fa fa-envelope" />
                    <a
                      style={{ color: 'black' }}
                      href="mailto:office@abmartproperty.rs"
                    >
                      office@abmartproperty.rs
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-phone" />
                    <a style={{ color: 'black' }} href="tel:011/242-02-15">
                      011/242-02-15
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-clock-o" />
                    Pon-Pet 8:00 - 16:00
                  </li>
                </ul>
              </div>
              <div className="mt-topbar-right clearfix">
                {/* <div className="appint-btn"><NavLink to={"#"} className="site-button">Make an Appointment</NavLink></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header main-bar-wraper">
          <div className="main-bar bg-white">
            <div
              className="container mobile-meni"
              id="logo-container"
              // style={{
              //   flexDirection: 'column',
              //   alignItems: 'center',
              //   justifyContent: 'center',
              // }}
            >
              <div className="logo-header" id="logo-header-container">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={'/'}>
                    <img
                      style={{
                        height: '100px',
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      src={logo.default}
                      alt="ABM"
                    />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggle collapsed"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              {/* ETRA Nav */}
              {/* <div className="extra-nav">
                <div className="extra-cell">
                  <NavLink to={"#"} onClick={this.handleSearchToggle}> 
                  <i className="fa fa-search" />
                  </NavLink>
                </div>
                <div className="extra-cell">
                  <NavLink to={"#"} className="contact-slide-show" onClick={this.handleQuoteToggle}><i className="fa fa-angle-left arrow-animation" /></NavLink>
                </div>
              </div> */}
              {/* ETRA Nav */}
              {/* Contact Nav */}
              <div
                className="contact-slide-hide "
                style={{
                  backgroundImage: 'url(' + bnr.default + ')',
                  right: isQuoteActive ? '0px' : '-500px',
                }}
              >
                <div className="contact-nav">
                  {/* <NavLink to={"#"} className="contact_close" onClick={this.handleQuoteToggle}>×</NavLink> */}
                  <div className="contact-nav-form p-a30">
                    <div className="contact-info   m-b30">
                      <div className="mt-icon-box-wraper center p-b30">
                        <div className="icon-xs m-b20 scale-in-center">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            Broj Telefona
                          </h5>
                          <p>011/242-02-15</p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper center p-b30">
                        <div className="icon-xs m-b20 scale-in-center">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            E-mail Adresa
                          </h5>
                          <p>office@abmartproperty.rs</p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper center p-b30">
                        <div className="icon-xs m-b20 scale-in-center">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">Adresa</h5>
                          <p>Hadži Melentijeva 28, Vračar, Beograd</p>
                        </div>
                      </div>
                    </div>
                    <div className="full-social-bg">
                      <ul>
                        <li>
                          <NavLink to={'#'} className="instagram">
                            <i className="fa fa-instagram" />
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="text-center">
                      <h4 className="font-weight-600">
                        © 2021 ABM Art Property
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* SITE Search */}
              {/* <div id="search" className={isSearchActive ? 'open' : null}> */}
              {/* <span className="close" onClick={handleSearchToggle} /> */}
              {/* <form
                  role="search"
                  id="searchform"
                  action="/search"
                  method="get"
                  className="radius-xl"
                >
                  <div className="input-group">
                    <input
                      defaultValue=""
                      name="q"
                      type="search"
                      placeholder="Type to search"
                    />
                    <span className="input-group-btn">
                      <button type="button" className="search-btn">
                        <i className="fa fa-search arrow-animation" />
                      </button>
                    </span>
                  </div>
                </form> */}
              {/* </div> */}
              {/* MAIN Vav */}
              <Navigation />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
