import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Banner from '../Elements/Banner';
import {
  constants,
  spratovi,
  brojeviSpratovaNeprodati,
  spratoviIB,
} from '../../constants/constants';
import BannerIB from '../Elements/BannerIB';
let bnrimg = require('./../../images/banner/IB.jpg');

const FloorIB = () => {
  const { broj } = useParams();
  // const brojeviSpratovaNeprodati = [1, 2];
  const history = useHistory();
  const brojeviSpratovaNeprodatiIB = [0, 1, 2];

  const floorUp = () => {
    let index = brojeviSpratovaNeprodatiIB.indexOf(
      brojeviSpratovaNeprodatiIB.find((sprat) => sprat == broj)
    );
    let sprat = brojeviSpratovaNeprodatiIB[index + 1];

    if (index == brojeviSpratovaNeprodatiIB.length - 1 || index == undefined) {
      history.push(
        '/internacionalnih-brigada/sprat/' + brojeviSpratovaNeprodatiIB[0]
      );
      return;
    }
    history.push('/internacionalnih-brigada/sprat/' + sprat);
  };

  const floorDown = () => {
    let index = brojeviSpratovaNeprodatiIB.indexOf(
      brojeviSpratovaNeprodatiIB.find((sprat) => sprat == broj)
    );
    let sprat = brojeviSpratovaNeprodatiIB[index - 1];

    if (index == undefined || index <= 0) {
      history.push(
        '/internacionalnih-brigada/sprat/' +
          brojeviSpratovaNeprodatiIB[brojeviSpratovaNeprodatiIB.length - 1]
      );
      return;
    }
    history.push('/internacionalnih-brigada/sprat/' + sprat);
  };

  return (
    <>
      <BannerIB
        title=""
        pagename={broj == 0 ? 'Prizemlje' : 'Sprat: ' + broj}
        buildingName="Internacionalnih brigada 93"
        bgimage={bnrimg.default}
      />
      <div className="mobile-floor-buttons">
        <button
          className="m-b15 site-button  blue text-uppercase m-r15"
          onClick={floorUp}
        >
          <i class="fas fa-arrow-circle-up"></i>{' '}
        </button>
        <button
          className="m-b15 site-button  blue text-uppercase m-r15"
          onClick={floorDown}
        >
          <i class="fas fa-arrow-circle-down"></i>{' '}
        </button>
      </div>
      <div className="blog-post date-style-3 blog-detail text-black">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="floor-buttons"
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            //   marginLeft: '50px',
            //   marginBottom: '30px',
            // }}
          >
            <button
              className="m-b15 site-button  blue text-uppercase m-r15"
              onClick={floorUp}
            >
              <i class="fas fa-arrow-circle-up"></i>{' '}
            </button>
            <button
              className="m-b15 site-button  blue text-uppercase m-r15"
              onClick={floorDown}
            >
              <i class="fas fa-arrow-circle-down"></i>{' '}
            </button>
          </div>
          <div className="mt-post-media clearfix m-b30">
            <ul className="grid-post">
              <li>
                <div className="portfolio-item">
                  {spratoviIB[broj]?.komponenta}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloorIB;
