import Floor0 from '../components/Elements/Floors/Floor0';
import Floor1 from '../components/Elements/Floors/Floor1';
import Floor2 from '../components/Elements/Floors/Floor2';
import Floor3 from '../components/Elements/Floors/Floor3';
import Floor4 from '../components/Elements/Floors/Floor4';
import Floor7 from '../components/Elements/Floors/Floor7';
import Floor5 from '../components/Elements/Floors/Floor5';
import Floor6 from '../components/Elements/Floors/Floor6';

import Floor from '../components/Pages/Floor';
import Floor1IB from '../components/Elements/Floors/Floor1IB';
import Floor2IB from '../components/Elements/Floors/Floor2IB';
import GarageIB from '../components/Pages/GarageIB';

export const spratovi = [
  { id: 0, komponenta: <Floor0 /> },
  { id: 1, komponenta: <Floor1 /> },
  { id: 2, komponenta: <Floor2 /> },
  { id: 3, komponenta: <Floor3 /> },
  { id: 4, komponenta: <Floor4 /> },
  { id: 5, komponenta: <Floor5 /> },
  { id: 6, komponenta: <Floor6 /> },
  { id: 7, komponenta: <Floor7 /> },
];

export const spratoviIB = [
  { id: 1, komponenta: <GarageIB /> },
  { id: 2, komponenta: <Floor1IB /> },
  { id: 3, komponenta: <Floor2IB /> },
];

export const brojeviSpratovaNeprodati = [0, 1, 2, 3, 4, 5, 6, 7];
export const stanoviIB = {
  stan1: {
    slika2: '/ib/stan1/Osnovapr.png',
    slika3: '/ib/stan1/Stan1.png',
    kvadratura: {
      '1. Hodnik': 7.92,
      '2. Toalet': 2.72,
      '3. Kuhinja': 8.77,
      '4. Trpezarija': 12.64,
      '5. Dnevna soba': 23.24,
      '6. Predsoblje': 4.93,
      '7. Kupatilo': 3.69,
      '8. Spavaća soba 1': 11.04,
      '9. Spavaća soba 2': 12.22,
      '10. Master soba': 14.22,
      '10a. Garderober': 4.25,
      '10b. Master kupatilo': 8.26,
      '11. Terasa': 7.44,
      '12. Zadnje dvorište': 35.0,
      Ukupno: 156.35,
    },
  },
  stan2: {
    slika2: '/ib/stan2/Osnova1.png',
    slika3: '/ib/stan2/Stan2.png',
    kvadratura: {
      '1. Hodnik': 7.9,
      '2. Toalet': 2.79,
      '3. Kuhinja': 8.77,
      '4. Trpezarija': 12.64,
      '5. Dnevna soba': 23.24,
      '6. Predsoblje': 4.93,
      '7. Kupatilo': 3.69,
      '8. Spavaća soba 1': 11.04,
      '9. Spavaća soba 2': 12.22,
      '10. Master soba': 14.35,
      '10a. Garderober': 4.13,
      '10b. Master kupatilo': 8.3,
      '11. Terasa': 7.44,
      '12. Terasa': 1.67,

      Ukupno: 123.11,
    },
  },
};

export const stanovi = {
  lokal1: {
    slika1: '/stanovi/poslovni/Lokal1.png',
  },
  lokal2: {
    slika1: '/stanovi/poslovni/Lokal2.png',
  },
  lokal3: {
    slika1: '/stanovi/poslovni/Lokal3.png',
  },
  lokal4: {
    slika1: '/stanovi/poslovni/Lokal4.png',
  },
  garaze1: {
    slika1: '/stanovi/garaze/Garaze1.png',
  },
  garaze2: {
    slika1: '/stanovi/garaze/Garaze2.png',
  },
  garaze3: {
    slika1: '/stanovi/garaze/Garaze3.png',
  },

  stan2: {
    slika1: '/stanovi/stan2/Poz2.png',
    slika2: '/stanovi/stan2/Sema2.png',
    slika3: '/stanovi/stan2/Stan2.png',
    kvadratura: {
      '1. Hodnik': 2.77,
      '2. Kuhinja': 5.21,
      '3. Dnevna soba': 24.4,
      '4. Spavaća soba': 6.84,
      '5. Hodnik': 2.62,
      '6. Spavaća soba': 11.62,
      '7. Terasa': 1.0,
      Ukupno: 58.58,
    },
  },
  stan4: {
    slika1: '/stanovi/stan4/Poz4.png',
    slika2: '/stanovi/stan4/Sema4.png',
    slika3: '/stanovi/stan4/Stan4.png',
    kvadratura: {
      '1. Hodnik': 19.2,
      '2. Dnevna soba': 44.79,
      '3. Kuhinja': 13.29,
      '4. Ostava/vešeraj': 5.12,
      '5. Predsoblje': 3.11,
      '6. Toalet': 2.22,
      '7. Kupatilo': 4.08,
      '8. Spavaća soba': 10.48,
      '9. Spavaća soba': 17.48,
      '10. Garderober': 3.61,
      '11. Master spavaća soba': 13.75,
      '12. Terasa': 27.83,
      Ukupno: 168.68,
    },
  },
  stan8: {
    slika1: '/stanovi/stan8/Poz8.png',
    slika2: '/stanovi/stan8/Sema8.png',
    slika3: '/stanovi/stan8/Stan8.png',
    kvadratura: {
      '1. Hodnik': 4.1,
      '2. Kuhinja': 10.37,
      '3. Dnevna soba': 16.44,
      '4. Hodnik': 1.82,
      '5. Kupatilo': 4.05,
      '6. Spavaća soba': 10.91,
      '7. Spavaća soba': 10.86,
      '8. Terasa': 1.56,
      Ukupno: 60.11,
    },
  },

  stan9: {
    slika1: '/stanovi/stan9/Poz9.png',
    slika2: '/stanovi/stan9/Sema9.png',
    slika3: '/stanovi/stan9/Stan9.png',
    kvadratura: {
      '1. Hodnik': 12.02,
      '2. Toalet': 3.44,
      '3. Kuhinja': 5.78,
      '4. Dnevna soba': 29.24,
      '5. Hodnik': 9.17,
      '6. Kupatilo': 4.01,
      '7. Spavaća soba': 10.66,
      '8. Spavaća soba': 7.38,
      '9. Spavaća soba': 9.13,
      '10. Terasa': 6.97,
      Ukupno: 97.8,
    },
  },
  // stan11: {
  //   slika1: '/stanovi/stan11/Poz11.png',
  //   slika2: '/stanovi/stan11/Sema11.png',
  //   slika3: '/stanovi/stan11/Stan11.png',
  //   kvadratura: {
  //     '1. Hodnik': 2.77,
  //     '2. Kuhinja': 5.21,
  //     '3. Dnevna soba': 24.4,
  //     '4. Spavaća soba': 6.84,
  //     '5. Hodnik': 2.62,
  //     '6. Spavaća soba': 11.62,
  //     '7. Terasa': 1.0,
  //     Ukupno: 58.58,
  //   },
  // },
  // stan13: {
  //   slika1: '/stanovi/stan13/Poz13.png',
  //   slika2: '/stanovi/stan13/Sema13.png',
  //   slika3: '/stanovi/stan13/Stan13.png',
  //   kvadratura: {
  //     '1. Hodnik': 4.1,
  //     '2. Kuhinja': 10.37,
  //     '3. Dnevna soba': 16.44,
  //     '4. Hodnik': 1.82,
  //     '5. Kupatilo': 4.05,
  //     '6. Spavaća soba': 10.91,
  //     '7. Spavaća soba': 10.86,
  //     '8. Terasa': 1.56,
  //     Ukupno: 60.11,
  //   },
  // },

  stan16: {
    slika1: '/stanovi/stan16/Poz16.png',
    slika2: '/stanovi/stan16/Sema16.png',
    slika3: '/stanovi/stan16/Stan16.png',
    kvadratura: {
      '1. Hodnik': 2.77,
      '2. Kuhinja': 5.21,
      '3. Dnevna soba': 24.4,
      '4. Spavaća soba': 6.84,
      '5. Hodnik': 2.62,
      '6. Spavaća soba': 11.62,
      '7. Terasa': 1.0,
      Ukupno: 58.58,
    },
  },
  stan30: {
    slika1: '/stanovi/stan30/Poz30.png',
    slika2: '/stanovi/stan30/Sema30.png',
    slika3: '/stanovi/stan30/Stan30.png',
    kvadratura: {
      '1. Hodnik': 4.56,
      '2. Toalet': 1.33,
      '3. Dnevna soba': 34.57,
      '4. Terasa': 5.75,
      '5. Kuhinja': 9.94,
      '6. Predsoblje': 5.92,
      '7. Kupatilo': 4.33,
      '8. Spavaća soba': 8.22,
      '9. Spavaća soba': 11.46,
      '10. Kupatilo': 3.87,
      '11. Terasa': 27.02,
      Ukupno: 125.68,
    },
  },
};
