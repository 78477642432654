import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

const loadScript = (src) => {
  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function () {
      resolve();
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body.appendChild(script);
    document.body.removeChild(script);
  });
};

const Navigation = (props) => {
  const [windowLocation, setWindowLocation] = useState('');

  useEffect(() => {
    loadScript('./assets/js/masonary.js');
  }, []);

  const path = useRouteMatch();

  return (
    <>
      <div
        className={
          props.bgcolor !== ''
            ? `header-nav navbar-collapse collapse ${props.bgcolor}`
            : 'header-nav navbar-collapse collapse'
        }
      >
        <ul className="nav navbar-nav">
          <li className={window.location.pathname === '/' ? 'active' : ''}>
            <NavLink to={'/'}>Naslovna</NavLink>
          </li>
          <li className={window.location.pathname === '/onama' ? 'active' : ''}>
            <NavLink to={'/onama'}>O Nama</NavLink>
          </li>
          <li
            className={window.location.pathname === '/projekti' ? 'active' : ''}
          >
            <NavLink to={'/projekti'}>Projekti</NavLink>
          </li>
          <li
            className={window.location.pathname === '/kontakt' ? 'active' : ''}
          >
            <NavLink to={'/kontakt'}>Kontakt</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navigation;
