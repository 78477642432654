import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import GoogleMaps from 'simple-react-google-maps';

import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import Dravska from '../Elements/Buildings/Dravska';
import Banner from '../Elements/Banner';
import PostGallery from '../Elements/PostGallery';
import MilanaRakicaCharacteristics from '../Elements/MilanaRakicaCharacteristics';
import DravskaCharacteristics from '../Elements/DravskaCharacteristics';
import InternacionalnihBrigada from '../Elements/Buildings/InternacionalnihBrigada';

var bnrimg = require('./../../images/banner/IB.jpg');

const ProjectDetailInternacionalnihBrigada = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <div className="page-content">
        <Banner
          title="Internacionalnih brigada 93"
          pagename=""
          bgimage={bnrimg.default}
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="project-detail-outer">
              <div className="m-b0"></div>
              <div className="blog-post date-style-3 blog-detail text-black">
                <div className="mt-post-media clearfix m-b30">
                  <ul className="grid-post">
                    <li>
                      <div className="portfolio-item">
                        <InternacionalnihBrigada />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="project-detail-containt">
                <div className="bg-white text-black">
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one ">
                          O Projektu
                        </h2>
                      </div>
                    </div>
                  </div>{' '}
                  <p>
                    Stambeni objekat u ulici Internacionalnih brigada 93 se
                    nalazi u mirnom delu Vračara, u blizini Neimarskog parka. U
                    okviru projekta predviđena su 4 četvorosobna stana. Svi
                    stanovi su dvostrano orijentisani: dnevne zone su
                    orijentisane ka ulici Internacionalnih brigada, dok su noćne
                    zone okrenute ka dvorišnoj strani parcele. Visok nivo
                    tehničke opremljenosti zgrade, visok kvalitet upotrebljenih
                    materijala i završne obrade čine ovaj objekat idealnim
                    mestom za prijatan i udoban život.
                  </p>
                  <div className="m-b0">
                    <div className="mt-divider divider-1px  bg-black">
                      <i className="icon-dot c-square" />
                    </div>
                  </div>
                  {/* <DravskaCharacteristics /> */}
                  <div className="gmap-outline">
                    <GoogleMaps
                      apiKey={'AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0'}
                      style={{ height: '400px', width: '100%' }}
                      zoom={15}
                      center={{
                        lat: 44.79499,
                        lng: 20.47688,
                      }}
                      markers={{
                        lat: 44.79499,
                        lng: 20.47688,
                      }}
                    />
                  </div>
                  {/* <PostGallery /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailInternacionalnihBrigada;
