import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
// import ModalVideo from 'react-modal-video';
// import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import Header from '../Elements/Header';
import Banner from '../Elements/Banner';
import Footer from '../Elements/Footer';

import { stanovi } from '../../constants/constants';

var bnrimg = require('./../../images/banner/IB.jpg');

const GarageIB = () => {
  const { broj, id } = useParams();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="page-content">
        {/* <Banner
          title={'Garaža Nivo: ' + id}
          pagename={`Garaža ${id}`}
          bgimage={bnrimg.default}
          broj={broj}
        /> */}

        {/* SECTION CONTENT START */}
        <div className="section-full  inner-page-padding m-t100 m-b100">
          <div className="container">
            <div className="project-detail-outer">
              <div className="m-b0">
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media clearfix m-b30">
                    <div className="portfolio-item" id="garageIB">
                      <img
                        src="/ib/garaza/garaza.png"
                        alt="Slika1"
                        id="garazeimgib"
                        style={{
                          width: '500px',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END  */}
        {/* <SimilarProjects /> */}
      </div>
    </>
  );
};

export default GarageIB;
