// import React from 'react';
// import { useParams } from 'react-router';
// import Banner from '../Elements/Banner';
// import Footer from '../Elements/Footer';
// import Header from '../Elements/Header';
// var bnrimg = require('./../../images/banner/4.jpg');

// const Stan = () => {
//   const { broj } = useParams();

//   return (
//     <>
//       <Header />
//       <Banner
//         title="Putting a plan to action, to assure your satisfaction!"
//         pagename="Contact Us"
//         bgimage={bnrimg.default}
//       />
//       STAN BROJ {broj} PAGE
//       <Footer />
//     </>
//   );
// };

// export default Stan;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
// import ModalVideo from 'react-modal-video';
// import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import Banner from '../Elements/Banner';

import { stanoviIB } from '../../constants/constants';

var bnrimg = require('./../../images/banner/IB.jpg');

const ApartmentIB = () => {
  const { broj, id } = useParams();
  const history = useHistory();
  const a = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <div className="page-content">
        <Banner
          title={'Stan Broj: ' + id}
          pagename={`Stan ${id}`}
          bgimage={bnrimg.default}
          broj={broj}
        />
        <div
          className="section-full p-tb80 inner-page-padding"
          style={{ backgroundColor: '#eeeeee' }}
        >
          <div className="container">
            <div className="project-detail-outer">
              <div className="m-b0">
                <div className="row">
                  <div className="col-md-12 col-sm-4">
                    <div className="project-detail-pic m-b30">
                      <div className="mt-media">
                        <img
                          src={stanoviIB[`stan${id}`]?.slika3}
                          alt="Slika1"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="project-detail-outer">
              <div className="m-b0">
                <div className="m-b30">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="mt-box">
                        <img
                          src={stanoviIB[`stan${id}`]?.slika2}
                          alt="Render"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="product-block">
                        <div className="row">
                          <table className="table table-hover">
                            <thead>
                              <br /> <br /> <br />
                              <tr>
                                <th>Prostorija</th>
                                <th>
                                  m<sup>2</sup>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {stanoviIB[`stan${id}`] &&
                                Object.entries(
                                  stanoviIB[`stan${id}`]?.kvadratura
                                ).map(([key, val]) => (
                                  <>
                                    <tr>
                                      <td
                                        className={
                                          key !== 'Ukupno'
                                            ? 'classOstalo'
                                            : 'classUkupno'
                                        }
                                      >
                                        {key}
                                      </td>
                                      <td
                                        className={
                                          key !== 'Ukupno'
                                            ? 'classOstalo'
                                            : 'classUkupno'
                                        }
                                      >
                                        {val.toFixed(2)}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentIB;
