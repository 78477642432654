import React from 'react';
import { NavLink } from 'react-router-dom';

const milanaRakica = [
  {
    count: 1,
    title: 'Podovi',
    icon: 'Dravska6/Ikonice/podovi.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>Italijanska granitna keramika (hodnici, kupatila, kuhinje)</li>
          <li>Parket višeslojni natur hrast I klasa</li>
          <li>Na terasama podna protivklizna keramika</li>
        </ul>
      </div>
    ),
  },
  {
    count: 2,
    title: 'Zidovi',
    icon: 'Dravska6/Ikonice/zidovi.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>Italijanska granitna keramika (kupatila, kuhinje)</li>
          <li>Disperzivne boje</li>
        </ul>
      </div>
    ),
  },
  {
    count: 3,
    title: 'Plafoni',
    icon: 'Dravska6/Ikonice/plafoni.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>Spušteni plafoni u kupatilima od gips-kartonskih ploča</li>
          <li>Poludisperzija u stanovima</li>
        </ul>
      </div>
    ),
  },

  {
    count: 4,
    title: 'Grejni Sistem',
    icon: 'Dravska6/Ikonice/grejni_sistem.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>Etažni sistem, kotao na struju</li>
          <li>Aluminijumski radijatori i sušači peškira u kupatilima</li>
        </ul>
      </div>
    ),
  },
  {
    count: 5,
    title: 'Oprema za Kupatila',
    icon: 'Dravska6/Ikonice/oprema_za_kupatila.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>
            Konzolne WC šolje Ideal Standard sa ugradnim vodokotlićima Roca
          </li>
          <li>Umivaonici Vitra i Slavine Rosan</li>
          <li>Paravani za tuš kabine - kaljeno staklo d=8mm</li>
          <li>Baterije za tuš kabine Rosan uzidne</li>
          <li>Ugaone tuš kabine Kolpa san</li>
        </ul>
      </div>
    ),
  },
  {
    count: 6,
    title: 'Spoljna Stolarija',
    icon: 'Dravska6/Ikonice/spoljna_stolarija.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>PVC stolarija REHAU Brilliant - Design 70mm, 5 komora</li>
          <li>Aluminijumske roletne</li>
        </ul>
      </div>
    ),
  },
  {
    count: 7,
    title: 'Unutrašnja Stolarija',
    icon: 'Dravska6/Ikonice/unutrasnja_stolarija.png',
    description: (
      <div className="section-content">
        <ul className="list-simple p-l15">
          <li>
            Unutrašnja vrata su izgrađena u kombinaciji HDF-a i drveta, farbana
            poliuretanskim lakovima, okovana brodskim šarkama
          </li>
        </ul>
      </div>
    ),
  },
];

let img1 = require('./../../images/background/ptn-1.png');

const MilanaRakicaCharacteristics = () => {
  return (
    <>
      <div
        className="section-full p-t80 p-b50 bg-white mobile-page-padding"
        style={{ backgroundImage: 'url(' + img1.default + ')' }}
      >
        <div className="section-content">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head m-b50">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">
                      ENTERIJERSKA
                    </span>{' '}
                    OBRADA
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="row services-v2 no-col-gap">
              {milanaRakica.map((item, index) => (
                <div
                  className="col-md-3 col-sm-6 col-xs-6 col-xs-100pc"
                  key={index}
                >
                  <div
                    // style={{
                    //   height: '400px',
                    // }}
                    className="mt-icon-box-wraper bdr-r-1 bdr-solid bdr-gray height-auto"
                  >
                    <div className="relative  p-a30">
                      <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                        <span className="icon-cell">
                          <img src={item.icon} alt="" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                // <div
                //   className="col-md-3 col-sm-6 col-xs-6 col-xs-100pc"
                //   key={index}
                // >
                //   <div className="mt-icon-box-wraper bdr-l-1 bdr-solid bdr-gray">
                //     <div className="relative  p-a30">
                //       <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                //         <span className="icon-cell">
                //           <img src={item.icon} alt="" />
                //         </span>
                //       </div>
                //       <div className="icon-content">
                //         <h4 className="mt-tilte m-b25">{item.title}</h4>
                //         <p>
                //           lorem Ipsum available, but the majority have suffered
                //           alterati on in some form, by ious by accident.
                //         </p>
                //         <NavLink
                //           to={'/services'}
                //           className="site-button-link"
                //           data-hover="Read More"
                //         >
                //           Read More{' '}
                //           <i className="fa fa-angle-right arrow-animation" />
                //         </NavLink>
                //       </div>
                //     </div>
                //   </div>
                // </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MilanaRakicaCharacteristics;
