import React from 'react';
import { NavLink } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/banner/1.jpg');

class PostGallery extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 5000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
    };
    return (
      <>
        {/* SECTION CONTENT START */}

        {/* BLOG START */}

        <div className="mt-post-media m-t100 m-b100">
          {/*Fade slider*/}

          <OwlCarousel
            className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30"
            {...options}
          >
            <div className="item">
              <div className="aon-thum-bx">
                <img
                  src={require('./../../images/blog/default/thum1.jpg').default}
                  alt=""
                />
              </div>
            </div>
            <div className="item">
              <div className="aon-thum-bx">
                <img
                  src={require('./../../images/blog/default/thum2.jpg').default}
                  alt=""
                />
              </div>
            </div>
            <div className="item">
              <div className="aon-thum-bx">
                <img
                  src={require('./../../images/blog/default/thum3.jpg').default}
                  alt=""
                />
              </div>
            </div>
          </OwlCarousel>
          {/*fade slider END*/}
        </div>

        {/* SECTION CONTENT END */}
      </>
    );
  }
}

export default PostGallery;
